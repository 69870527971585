import React from 'react';
import './style.scss';

import Navbar from './navbar';
import Video from './video';

const Header = () => (
	<section className="hero gradientBg is-fullheight">
		<Navbar />

		<div className="hero-body">
			<div className="container">
				<div className="columns is-vcentered">
					<div className="column">
						<Video />
					</div>
					<div className="column">
						<h1 className="has-text-white is-size-1">
							Time to discover <br />Malbork Castle
					</h1>
						<p className="has-text-white is-size-2">
							<b>A unique tour to the largest brick fortress in the world</b>
						</p>
						<p className="date"></p>

					</div>
				</div>
			</div>
		</div>
	</section >
);

export default Header;
